import React, { useEffect, useMemo } from 'react';
import AppBar from './AppBar';
import { Redirect, Route, Switch, withRouter } from 'react-router-dom';
import { Home } from '../routes';
import Footer from './Footer';
import ModalPasswordReset from './ModalPasswordReset';
import ModalEmailSent from './ModalEmailSent';
import GDPR from '../routes/GDPR.jsx';
import Terms from '../routes/Terms.jsx';
import Imprint from '../routes/Imprint.jsx';
import SignIn from '../routes/SignIn/SignIn';
import { useLang } from './AppState';
import i18next from 'i18next';
import { ApolloProvider } from 'react-apollo';
import { getApolloClient } from '../graphql/apolloClient';
import ModalSignup from './Signup/ModalSignup';
import ModalLogin from './Login/ModalLogin';
import PasswordChangeRedirect from './PasswordChangeRedirect';
import CookieConsent from './CookieConsent';
import { redirectSignup, shouldRedirectToBeta } from '../utils/redirectTools';

const LanguageRoutes = ({ match }) => {
  const [lang, setLang] = useLang();
  const params = match.params || {};
  const nextLang = (params.lang || '').toLowerCase();

  useEffect(() => {
    if (nextLang && lang !== nextLang) {
      setLang(nextLang);
    }
  }, [lang, nextLang]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const urlSignUp = match.url + '/signup';
    if (shouldRedirectToBeta() && urlSignUp === window.location.pathname) {
      console.log('redirectSignup');
      redirectSignup(null, lang);
    }
  });

  return (
    <Switch>
      <Route exact path={match.url + '/'} component={Home} />
      <Route
        path={match.url + '/(signin|login|passwordreset|signup|sent|locked)'}
        component={Home}
      />
      <Route path={match.url + '/impressum'} component={Imprint} />
      <Route path={match.url + '/terms'} component={Terms} />
      <Route path={match.url + '/gdpr'} component={GDPR} />
      <Redirect to={match.url + '/'} />
    </Switch>
  );
};

const ModalBoxesRoutes = () => (
  <Route
    path="/:lang(de|en)/"
    render={({ match }) => {
      return (
        <Switch>
          <Route exact path={match.url + '/signin'} component={SignIn} />
          <Route exact path={match.url + '/login'} component={ModalLogin} />
          <Route exact path={match.url + '/passwordreset'} component={ModalPasswordReset} />
          <Route exact path={match.url + '/passwordreset/sent'} component={ModalEmailSent} />
          <Route
            exact
            path={match.url + '/passwordreset/verify/:hash'}
            component={PasswordChangeRedirect}
          />
          <Route exact path={match.url + '/signup'} component={ModalSignup} />
        </Switch>
      );
    }}
  />
);

const Layout = () => {
  const [lang] = useLang();
  i18next.on('languageChanged', lng => {
    document.documentElement.setAttribute('lang', lng);
  });
  const apolloClient = useMemo(() => getApolloClient(lang), [lang]);

  return (
    <ApolloProvider client={apolloClient}>
      <AppBar />
      <main className="main">
        <Switch>
          <Redirect exact from="/" to={`/${lang}/`} />
          <Route path="/:lang(de|en)/" component={LanguageRoutes} />
          <Redirect from="/resetpassword/:hash" to={`/${lang}/passwordreset/verify/:hash`} />
          <Redirect exact from="/:catchAll" to={`/${lang}/:catchAll`} />
        </Switch>
      </main>
      <Footer />
      <CookieConsent />
      <ModalBoxesRoutes />
    </ApolloProvider>
  );
};

Layout.propTypes = {};

Layout.defaultProps = {};

export default withRouter(Layout);
